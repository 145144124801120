import $ from "jquery";
var ua = navigator.userAgent;
/Safari|iPhone/i.test(ua) && 0 == /chrome/i.test(ua) && $("#aside-nav").addClass("no-filter");
var drags = {
    down: !1,
    x: 0,
    y: 0,
    winWid: 0,
    winHei: 0,
    clientX: 0,
    clientY: 0
}
  , asideNav = $("#aside-nav")[0]
  , getCss = function(b, c) {
    return b.currentStyle ? b.currentStyle[c] : document.defaultView.getComputedStyle(b, !1)[c]
};
$("#aside-nav").on("mousedown", function(b) {
    drags.down = !0,
    drags.clientX = b.clientX,
    drags.clientY = b.clientY,
    drags.x = getCss(this, "right"),
    drags.y = getCss(this, "top"),
    drags.winHei = $(window).height(),
    drags.winWid = $(window).width(),
    $(document).on("mousemove", function(c) {
        if (drags.winWid > 640 && (c.clientX < 120 || c.clientX > drags.winWid - 50)) {
            return !1
        }
        if (c.clientY < 180 || c.clientY > drags.winHei - 120) {
            return !1
        }
        var d = c.clientX - drags.clientX
          , f = c.clientY - drags.clientY;
        asideNav.style.top = parseInt(drags.y) + f + "px";
        asideNav.style.right = parseInt(drags.x) - d + "px"
    })
}).on("mouseup", function() {
    drags.down = !1,
    $(document).off("mousemove")
});

$("#aside-nav").on("touchstart", function(b) {
    b = b.targetTouches[0];
    drags.down = !0,
    drags.clientX = b.clientX,
    drags.clientY = b.clientY,
    drags.x = getCss(this, "right"),
    drags.y = getCss(this, "top"),
    drags.winHei = $(window).height(),
    drags.winWid = $(window).width(),
    $(document).on("touchmove", function(c) {
        c = c.targetTouches[0];
        if (drags.winWid > 640 && (c.clientX < 120 || c.clientX > drags.winWid - 50)) {
            return !1
        }
        if (c.clientY < 180 || c.clientY > drags.winHei - 120) {
            return !1
        }
        var d = c.clientX - drags.clientX
          , f = c.clientY - drags.clientY;
        asideNav.style.top = parseInt(drags.y) + f + "px";
        asideNav.style.right = parseInt(drags.x) - d + "px"
    })
}).on("touchend", function() {
    drags.down = !1,
    $(document).off("touchmove")
});
